<template>
<el-container class="root-container">
  <nav-bar :nav-list="navList" :is-collapse="isCollapse" class="nav-bar"/>
  <el-container>
    <el-header style="padding:0">
      <admin-home-top @sideBarStatus="getsideBarStatus" :is-collapse="isCollapse" ></admin-home-top>
    </el-header>
    <el-main>
      <router-view/>
    </el-main>
  </el-container>
</el-container>
</template>
<script>
import AdminHomeTop from './components/top'
import NavBar from './components/left.vue'
export default {
  name: 'AdminHome',
  components: {
    AdminHomeTop,
    NavBar
  },
  data () {
    return {
      isCollapse: true,
      navList: [
          {
              icon: '',
              name: 'Posts',
              items: [
                {
                  path: '/admin/article',
                  icon: '',
                  name: 'list',
                }
              ]
          },
          {
              icon: '',
              name: 'Tags',
              items: [
                {
                  path: '/admin/tag',
                  icon: '',
                  name: 'list',
                }
              ]
          },
          {
              icon: '',
              name: 'Users',
              items: [
                {
                  path: '/admin/user',
                  icon: '',
                  name: 'list',
                }
              ]
          }
      ]}
  },
  methods: {
    getsideBarStatus (status) {
      this.isCollapse = status
    }
  }
}
</script>
<style lang="stylus" scoped>
.root-container
  width: 100%
  height: 100vh
</style>
