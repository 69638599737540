<template>
  <el-menu
    class="el-menu-vertical"
    :collapse="isCollapse"
    :router="true"
    default-active="0"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b">
    <el-submenu  v-for="(navItem, index) in navList" :key="index" :index="getSubIndex(index)">
      <template slot="title">
        <i class="el-icon-location"></i>
        <span>{{navItem.name}}</span>
      </template>
       <el-menu-item-group v-for="(child, subIndex) in navItem.items" :key="subIndex">
        <el-menu-item :index="child.path">{{child.name}}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<script>
  export default {
      name: 'NavBar',
      props: ['navList','isCollapse'],
    methods: {
      getSubIndex(index, subindex = undefined) {
        return subindex ? `${index}-${subindex}` : `${index}`
      }
    }
  }
</script>

<style lang="stylus" scoped>
.el-menu-vertical
  height: 100vh
.el-menu-vertical:not(.el-menu--collapse)
    width: 400px
    min-height: 400px
</style>>
